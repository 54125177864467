export default {
	login: '/operation-api/v1/Sessions', //登录
	getChinaRegions: '/common-api/v1/ChinaRegions.json', //获取省市区数据
	getRegionManagementGroups: '/governance-api/v1/RegionManagementGroups', //获取区域管理组
	modifyPasswords: '/operation-api/v1/Passwords', //修改密码
	getMedicalWorkers: '/governance-api/v1/MedicalWorkers', //获取医护人员
	getPeople: '/governance-api/v1/People', //获取人员
	getDictionaryItems: '/common-api/v1/DictionaryItems.json', //获取字典项
	getMedicalOrganizations: '/governance-api/v1/MedicalOrganizations', //获取医疗机构
	getVehicles: '/governance-api/v1/Vehicles', //获取车辆
	getDevices: '/governance-api/v1/Devices', //获取车辆
	getTourPlans: '/governance-api/v1/TourPlans', //获取巡查计划
	getRegionPriorityDiseases: '/governance-api/v1/RegionPriorityDiseases', //获取重点疾病
	getRegionTourIndexes: '/governance-api/v1/RegionTourIndexes', //获取巡诊覆盖率
	getBaseData: '/governance-api/v1/BaseData', //获取基础数据
	getTalentDoctor: '/governance-api/v1/TalentDoctor', //获取人才医生
	getTalentTraining: '/governance-api/v1/TalentTraining', //获取培训
	getDictionaries: '/common-api/v1/Dictionaries', //获取字典
	getSmartDeviceModel: '/governance-api/v1/SmartDeviceModel', //获取智能设备型号
	getAttachmentGroups: '/common-api/v1/AttachmentGroups.json', //获取附件组
};
