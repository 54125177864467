import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 安装 VueRouter 插件
Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout/index';
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login'),
		hidden: true,
		meta: {
			title: '登录',
		},
	},

	{
		path: '/404',
		component: () => import('@/views/error/404'),
		hidden: true,
		meta: {
			title: '404',
		},
	},
	{
		path: '/',
		component: Layout,
		redirect: '/institutionManager',
	},

	{
		path: '/institutionManager',
		component: Layout,
		redirect: '/institutionManager/hospitalInfo',
		name: 'institutionManager',
		meta: {
			title: '机构管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/institutionManager/hospitalInfo',
				name: 'hospitalInfo',
				component: () => import('@/views/institutionManager/hospitalInfo'),
				meta: {
					title: '医院信息管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/institutionManager/departmentManager',
				name: 'departmentManager',
				component: () => import('@/views/institutionManager/departmentManager'),
				meta: {
					title: '科室管理',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/devicesResource',
		component: Layout,
		redirect: '/devicesResource/Manufacturer',
		name: 'devicesResource',
		meta: {
			title: '设备资源管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/devicesResource/manufacturer',
				name: 'manufacturer',
				component: () => import('@/views/devicesResource/manufacturer/index'),
				meta: {
					title: '厂家字典',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/devicesResource/diseaseManager',
				name: 'diseaseManager',
				component: () => import('@/views/devicesResource/diseaseManager/index'),
				meta: {
					title: '病症查询关联',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/devicesResource/deviceManager',
				name: 'deviceManager',
				component: () => import('@/views/devicesResource/deviceManager/index'),
				meta: {
					title: '设备管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/devicesResource/diagnosisCaseManager',
				name: 'diagnosisCaseManager',
				component: () => import('@/views/devicesResource/diagnosisCaseManager/index'),
				meta: {
					title: '诊疗方案',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/devicesResource/configManager',
				name: 'configManager',
				component: () => import('@/views/devicesResource/configManager/index'),
				meta: {
					title: '配置管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/devicesResource/useHistory',
				name: 'useHistory',
				component: () => import('@/views/devicesResource/useHistory'),
				meta: {
					title: '使用记录',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/mobilResourceManagement',
		component: Layout,
		redirect: '/mobilResourceManagement/medicalStaffData',
		name: 'mobilResourceManagement',
		meta: {
			title: '移动资源管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/mobilResourceManagement/benefitingInfo',
				name: 'benefitingInfo',
				component: () => import('@/views/mobilResourceManagement/benefitingInfo/index'),
				meta: {
					title: '惠民信息',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/mobilResourceManagement/healthCounseling',
				name: 'healthCounseling',
				component: () => import('@/views/mobilResourceManagement/healthCounseling/index'),
				meta: {
					title: '健康资讯',
					roles: ['admin', 'common'],
				},
			},

			{
				path: '/mobilResourceManagement/traditionalInfo',
				name: 'traditionalInfo',
				component: () => import('@/views/mobilResourceManagement/traditionalInfo/index'),
				meta: {
					title: '中药信息（中药养生）',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/mobilResourceManagement/healthCareProduct',
				name: 'healthCareProduct',
				component: () => import('@/views/mobilResourceManagement/healthCareProduct/index'),
				meta: {
					title: '保健产品',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/mobilResourceManagement/healthEquipment',
				name: 'healthEquipment',
				component: () => import('@/views/mobilResourceManagement/healthEquipment/index'),
				meta: {
					title: '康养设备',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/account',
		component: Layout,
		redirect: '/account/personalInfo',
		name: 'account',
		meta: {
			title: '账号设置',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/account/personalInfo',
				name: 'personalInfo',
				component: () => import('@/views/account/personalInfo'),
				meta: {
					title: '个人信息',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/mobileCustomerService',
		component: Layout,
		redirect: '/mobileCustomerService/helpAndConsultation',
		name: 'mobileCustomerService',
		meta: {
			title: '移动客户服务',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/mobileCustomerService/helpAndConsultation',
				name: 'helpAndConsultation',
				component: () => import('@/views/mobileCustomerService/helpAndConsultation/index'),
				meta: {
					title: '求助与咨询',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/mobileCustomerService/healthProduct',
				name: 'healthProduct',
				component: () => import('@/views/mobileCustomerService/healthProduct/index'),
				meta: {
					title: '健康产品',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/mobileCustomerService/moveHealthEquipment',
				name: 'moveHealthEquipment',
				component: () => import('@/views/mobileCustomerService/moveHealthEquipment/index'),
				meta: {
					title: '康养设备',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/chineseMedicineService',
		component: Layout,
		redirect: '/chineseMedicineService/infoManager',
		name: 'chineseMedicineService',
		meta: {
			title: '中医服务',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/chineseMedicineService/infoManager',
				name: 'infoManager',
				component: () => import('@/views/chineseMedicineService/infoManager/index'),
				meta: {
					title: '中医信息管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/chineseMedicineService/imgTextService',
				name: 'imgTextService',
				component: () => import('@/views/chineseMedicineService/imgTextService/index'),
				meta: {
					title: '中医图文服务',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true,
	},
];
const createRouter = () =>
	new VueRouter({
		// mode: 'history', // require service support
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

export default router;
