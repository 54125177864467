import { Session } from '@/utils/storage.js';
import api from '@/request/api';
import $http from '@/request/https';
const mapDataModule = {
	namespaced: true,
	state: {
		// 当前地址的省或者市或者区或者村的名字和code
		addressInfo: {},
		allData: {}, //地图初始化所有数据
		mapDataArr: [], //地图数据
		filterData: {},
		
		mapLevel:0,//当前地图点击的次数，点击省市区一次加一
		//地图上方统计
		statisticsList: [
			{ label: '机构数量', num: 0 },
			{ label: '巡诊组个数', num: 0 },
			{ label: '驻派组个数', num: 0 },
			{ label: '参与医生人数', num: 0 },
			{ label: '车辆数量', num: 0 },
			{ label: '设备数量', num: 0 },
		],
	},
	mutations: {
		// 设置地图层次
		getMapLevel(state, data){
			state.mapLevel=data
		},
		// 设置点击地图获取到的地址信息
		getAddressInfo(state, data) {
			state.addressInfo = data;
			state.filterData.regionCode=data.regionCode
		},

		// 设置地图选择地址，关键字搜索以及巡查类型筛选字段
		getMapFilter(state, data) {
			state.filterData = data;
		},
		// 设置地图数据
		getMapData(state, data) {
			state.mapDataArr = data;
		},
		// 设置地图上方统计数据
		getStatisticsData(state, val) {
			state.statisticsList[0].num = val.medicalOrganizations || 0;
			state.statisticsList[1].num = val.circuitDiagnosisGroups || 0;
			state.statisticsList[2].num = val.residencyGroups || 0;
			state.statisticsList[3].num = val.participateMedicalStaffs || 0;
			state.statisticsList[4].num = val.vehicles || 0;
			state.statisticsList[5].num = val.devices || 0;
		},
	},
	actions: {
		// 获取地图baseData数据
		setBaseData({ commit, state }, params) {
			return new Promise((resolve, reject) => {
				$http.get(api.getBaseData + '.json', { params: state.filterData }).then((res) => {
					if (res.data?.success) {
						console.log(res.data.collection, 'baseData');
						commit('getMapData',res.data.collection[0].baseDataList||[])
						commit('getStatisticsData', res.data.collection[0].baseAllData||[])
						resolve(200);
					} else {
						reject(500);
					}
				});
			});
		},
		// 设置地址信息
		async setAddressInfo({ commit }, data) {
			if (data) {
				commit('getAddressInfo', data);
			}
		},
	},
};

export default mapDataModule;
